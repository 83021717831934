@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: aachenb;
    /* font-weight: 400; */
    src: url(./assets/fonts/aachenb.ttf);
  }
  @font-face {
    font-family: aachenn;
    /* font-weight: 400; */
    src: url(./assets/fonts/aachenn.ttf);
  }
  html,
  body {
    overflow-x: hidden;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

@layer utilities {
  /*  Typography */
  .ml-form-embedWrapper {
    background: transparnet;
  }
}

@layer utilities {
  /*  Typography */
  .H1 {
    @apply font-auieu text-[4rem] font-black leading-none -tracking-[0.02em];
  }
  .H2 {
    @apply text-[1.5rem] font-bold leading-none -tracking-[0.02em];
  }
  .H3 {
    @apply font-auieu text-3xl font-black leading-none;
  }
  .eBOLD18 {
    @apply text-[1.125rem] font-extrabold leading-none -tracking-[0.02em];
  }
  .eBOLD20 {
    @apply text-[1.25rem] font-extrabold leading-none -tracking-[0.02em];
  }
  .eBOLD26 {
    @apply text-[1.625rem] font-extrabold leading-none -tracking-[0.02em];
  }
  .eBOLD32 {
    @apply text-[2rem] font-extrabold leading-none -tracking-[0.02em];
  }
  .BOLD11 {
    @apply text-[0.6875rem] font-bold leading-none -tracking-[0.02em];
  }
  .BOLD12 {
    @apply text-[0.75rem] font-bold leading-none -tracking-[0.02em];
  }
  .BOLD14 {
    @apply text-[0.875rem] font-bold leading-none -tracking-[0.02em];
  }
  .BOLD16 {
    @apply text-[1rem] font-bold leading-none -tracking-[0.02em];
  }
  .BOLD160 {
    @apply text-[1rem] font-bold leading-none;
  }
  .BOLD18 {
    @apply text-[1.125rem] font-bold leading-none -tracking-[0.02em];
  }
  .BOLD20 {
    @apply text-[1.25rem] font-bold leading-none -tracking-[0.02em];
  }
  .BOLD200 {
    @apply text-[1.25rem] font-bold leading-none;
  }
  .BOLD24 {
    @apply text-[1.5rem] font-bold leading-none -tracking-[0.02em];
  }
  .BOLD36 {
    @apply text-[2.25rem] font-bold leading-none -tracking-[0.02em];
  }
  .sBOLD11 {
    @apply text-[0.6875rem] font-semibold leading-none -tracking-[0.02em];
  }
  .sBOLD12 {
    @apply text-[0.75rem] font-semibold leading-none -tracking-[0.02em];
  }
  .sBOLD14 {
    @apply text-[0.875rem] font-semibold leading-none -tracking-[0.02em];
  }
  .sBOLD16 {
    @apply text-[1rem] font-semibold leading-none -tracking-[0.02em];
  }
  .sBOLD18 {
    @apply text-[1.125rem] font-semibold leading-none -tracking-[0.02em];
  }
  .MEDIUM10 {
    @apply text-[0.625rem] font-medium leading-none -tracking-[0.02em];
  }
  .MEDIUM11 {
    @apply text-[0.6875rem] font-medium leading-none -tracking-[0.02em];
  }
  .MEDIUM14 {
    @apply text-[0.875rem] font-medium leading-none -tracking-[0.02em];
  }
  .MEDIUM140 {
    @apply text-[0.875rem] font-medium leading-none;
  }
  .MEDIUM16 {
    @apply text-[1rem] font-medium leading-none -tracking-[0.02em];
  }

  .BUTTON1 {
    @apply bg-gradient-to-b from-[#FF9A17] to-[#E27A00] px-5 py-3 BOLD16 rounded-[0.625rem];
  }
  .BUTTON2 {
    @apply bg-gradient-to-b from-[#FFCB89] to-[#FFA939] px-5 py-3 BOLD16 rounded-[0.625rem];
  }
  .BUTTON-FORMAT {
    @apply px-5 py-3 BOLD16 rounded-[0.625rem];
  }

  /* GRADIENT */
  .GRADIENT-WHITE {
    background: linear-gradient(180deg, #ffffff 0%, #f8f8f8 100%);
  }
  .GRADIENT-GRAY-200 {
    background: linear-gradient(180deg, #dddddd 0%, #cccccc 100%);
  }
  .GRADIENT-GRAY-300 {
    background: linear-gradient(180deg, #999999 0%, #888888 100%);
  }
  .GRADIENT-GRAY-400 {
    background: linear-gradient(180deg, #666666 0%, #555555 100%);
  }
  .GRADIENT-GRAY-500 {
    background: linear-gradient(180deg, #444444 0%, #333333 100%);
  }
  .GRADIENT-YELLOW-100 {
    background: linear-gradient(180deg, #ffcb89 0%, #ffa939 100%);
  }
  .GRADIENT-I-YELLOW-100 {
    background: linear-gradient(180deg, #ffa939 0%, #ffcb89 100%);
  }
  .GRADIENT-YELLOW-200 {
    background: linear-gradient(180deg, #ff9a17 0%, #e27a00 100%);
  }

  .TITLE-SECTION-GRADIENT-L {
    background: linear-gradient(180deg, #fffaf4 0%, #ffe1bc 100%);
  }

  .TITLE-SECTION-GRADIENT-D {
    background: linear-gradient(180deg, #1e0e0a 0%, #311810 100%);
  }

  /* TEXT-SHADOW */
  .TEXT-SHADOW-GLOW-100 {
    text-shadow: 0px 0px 8px #ffd298;
  }
  .TEXT-SHADOW-GLOW-1 {
    text-shadow: 0px 0px 7px #ffd298;
  }
  .TEXT-SHADOW-GLOW-1-d {
    text-shadow: 0px 0px 7px #985600;
  }
  .TEXT-SHADOW-LANDING {
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  }
  /* DROP-SHADOW */
  .DROP-SHADOW-GLOW-3 {
    filter: drop-shadow(0px 0px 4px #ffd298);
  }
  .DROP-SHADOW-GLOW-3-d {
    filter: drop-shadow(0px 0px 4px #985600);
  }

  /* BG */
  .LIGHT-BG {
    background: radial-gradient(50% 50% at 50% 50%, #fff4e5 0%, #fffaf4 100%);
  }
  .DARK-BG {
    background: radial-gradient(50% 50% at 50% 50%, #311810 0%, #1e0e0a 100%);
  }

  /* Animation */
  .text-flow {
    animation: flowing 32s linear infinite;
    transform: translateX(100%);
  }

  @keyframes flowing {
    100% {
      transform: translateX(-100%);
    }
  }

  .cat-bounce {
    animation: cat-bounce 3s ease infinite;
  }

  .tail-wag {
    transform-origin: 100% 100%;
    animation: tail-wag 3s ease infinite;
  }

  .moon-flicker {
    animation: moon-flicker 1.8s ease infinite;
  }

  .ta-da {
    animation: ta-da 5s ease-out 1;
  }

  @keyframes cat-bounce {
    0% {
      bottom: -50px;
      left: -50px;
    }
    50% {
      bottom: -42px;
      left: -42px;
    }
    100% {
      bottom: -50px;
      left: -50px;
    }
  }

  @keyframes tail-wag {
    0% {
      transform: rotate(12deg);
    }
    25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(12deg);
    }
  }

  @keyframes moon-flicker {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes ta-da {
    0% {
      opacity: 0.1;
      transform: translate(20px, -20px) rotate(30deg);
    }
    5% {
      opacity: 0.4;
      transform: translate(11px, -11px) rotate(30deg);
    }
    15% {
      opacity: 1;
      transform: translate(2px, -2px) rotate(30deg);
    }
    20% {
      opacity: 1;
      transform: translate(0px, 0px) scale(1.04) rotate(30deg);
    }
    25% {
      opacity: 1;
      transform: translate(0px, 0px) scale(1) rotate(30deg);
    }
    30% {
      opacity: 1;
      transform: translate(0px, 0px) scale(1.04) rotate(30deg);
    }
    35% {
      opacity: 1;
      transform: translate(0px, 0px) scale(1) rotate(30deg);
    }
    40% {
      opacity: 1;
      transform: translate(0px, 0px) scale(1.04) rotate(30deg);
    }
    45% {
      opacity: 1;
      transform: translate(0px, 0px) scale(1) rotate(30deg);
    }
    60% {
      opacity: 1;
      transform: translate(0px, 0px) scale(1.04) rotate(30deg);
    }
    65% {
      opacity: 1;
      transform: translate(0px, 0px) scale(1) rotate(30deg);
    }
    100% {
      opacity: 0;
      transform: translate(0px, 0px) scale(1) rotate(30deg);
    }
  }
}
